<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="border mt-1"
      >
        <div class="m-2">
          <b-row>

            <div
              v-if="nodeEnv !== 'production'"
              class="mb-2 mr-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="d-flex"
                @click="updateToPublic()"
              >
                <feather-icon icon="ListIcon" />

                <span class="mr-25 align-middle">{{ $t("UPDATE_TO_PUBLIC") }}</span>
              </b-button>
            </div>
            <div
              class="mb-2 mr-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="d-flex"
                @click="openAbilitySort()"
              >
                <feather-icon icon="ListIcon" />

                <span class="mr-25 align-middle">{{ $t("Sort") }}</span>
              </b-button>
            </div>

          </b-row>
        </div>
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $t('Permission') }}</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="permissionsData"
          :fields="tableColumns"
          style="height: 60vh;"
        >
          <template #cell(have)="data">
            <b-form-checkbox
              v-model="permissionsData[data.index].have"
              disabled
            />

          </template>
          <template #cell(name)="data">
            <b-form-input
              :id="'name'+data.index"
              v-model="permissionsData[data.index].name"
              name="name"
              :placeholder="$t('Name')"
            />
          </template>
          <template #cell(subject)="data">
            {{ $t(data.item.subject) }}
          </template>
          <template #cell(path)="data">
            <b-form-input
              :id="'path'+data.index"
              v-model="permissionsData[data.index].path"
              name="path"
              :placeholder="$t('Path')"
            />
          </template>
          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-flex"
              @click="savePermission(data.index)"
            >
              <feather-icon
                icon="SaveIcon"
              />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BRow, BCol,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, franchiseAbility, companyAbility, eCommerceAbility,
} from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/root/ability'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'ability'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      nodeEnv: 'production',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    // tableColumns() {
    //   return [
    //     { key: 'name', label: this.$t('Name'), sortable: false },
    //     { key: 'subject', label: this.$t('Subject'), sortable: false },
    //     { key: 'path', label: this.$t('Path'), sortable: false },
    //     { key: 'action', label: this.$t('ACTION'), sortable: false },
    //   ]
    // },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    tableColumns() {
      return [
        { key: 'have', label: this.$t('Have'), sortable: false },
        { key: 'orderBy', label: this.$t('Order By'), sortable: false },
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'subject', label: this.$t('Subject'), sortable: false },
        { key: 'path', label: this.$t('Path'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    permissionsData() {
      let ability = []
      if (this.$route.params.id === 'root') {
        ability = rootAbility()
      } else if (this.$route.params.id === 'franchise') {
        ability = franchiseAbility()
      } else if (this.$route.params.id === 'company') {
        ability = companyAbility()
      } else if (this.$route.params.id === 'eCommerce') {
        ability = eCommerceAbility()
      }

      const resource = []
      const data = []
      let startCount = this.respData.length
      for (let index = 0; index < ability.length; index += 1) {
        if (!data.includes(ability[index].subject)) {
          data.push(ability[index].subject)
        }
      }
      for (let indexArray = 0; indexArray < data.length; indexArray += 1) {
        const index = this.respData.findIndex(result => result.subject === data[indexArray])
        if (index > -1) {
          resource.push({
            id: this.respData[index]._id, name: this.respData[index].name, subject: data[indexArray], path: this.respData[index].path, orderBy: this.respData[index].orderBy, have: true,
          })
        } else {
          // eslint-disable-next-line no-const-assign
          startCount += 1
          resource.push({
            id: null, name: '', subject: data[indexArray], path: '', orderBy: startCount, have: false,
          })
        }
      }
      return resource.sort((a, b) => {
        if (a.orderBy < b.orderBy) {
          return -1
        }
        if (a.orderBy > b.orderBy) {
          return 1
        }
        return 0
      })
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      id: this.$route.params.id,
      currentPage: 1,
      pageSize: 0,
      searchQuery: this.searchQuery,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getAbility`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // 
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
    this.nodeEnv = process.env.NODE_ENV
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    savePermission(index) {
      const obj = {
        id: this.permissionsData[index].id,
        services: this.$route.params.id,
        name: this.permissionsData[index].name,
        subject: this.permissionsData[index].subject,
        path: this.permissionsData[index].path,
      }
      if (this.permissionsData[index].id !== null) {
        this.show = true

        store
          .dispatch(`${STORE_MODULE_NAME}/updateAbility`, obj)
          .then(result => {
            
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Success'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Success'),
              },
            })
            // this.showToast('success', 'bottom-right')
          }).catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.show = false

            // this.showToast('danger', 'bottom-right')
          })
      } else {
        this.show = true

        store
          .dispatch(`${STORE_MODULE_NAME}/addAbility`, obj)
          .then(result => {
            
            this.show = false
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Success'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Success'),
              },
            })
            // this.showToast('success', 'bottom-right')
          }).catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.show = false

            // this.showToast('danger', 'bottom-right')
          })
      }
    },
    openAbilitySort() {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to enter')} ${this.$t('ability')} ${this.$t('sort')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/root/ability/sort/${this.$route.params.id}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    updateToPublic() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to update public')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch(`${STORE_MODULE_NAME}/updateToPublic`, { id: this.$route.params.id, url: process.env.VUE_APP_API_SERVER })
              .then(result => {
                this.show = false
                
                // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
              })
          }
        })
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/deleteAbility`, id)
              .then(result => {
                this.show = false
                
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
